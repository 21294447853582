import React from "react";
import pageStyle from "../../assets/css/page.module.css";
import workStyle from "../../assets/css/work.module.css";
import style from "../../assets/css/main.module.css";
import Menu from "../../components/menu";
import Footer from "../../components/footer";
import HeroPage from "../../components/hero.page";
import Hamburger from "../../components/hamburger";

import imgHeader from "../../assets/img/realisations/idmagic_0.jpg";
import { Link } from "gatsby";
import SEO from "../../components/seo";

export default () => (
  <div id="top">
    <Menu />
    <SEO
    title="ID Magic | Agence digitale | MDS Digital Agency"
    description="Nous avons créé le site internet de ID Magic étape par étape. MDS a également établit pour le client une stratégie SEO sur mesure."
    keywords="ID Magic"
    />
    <div className={style.menu_mobile}>
      <Hamburger />
    </div>
    <HeroPage img={imgHeader} />
    <div className={workStyle.info}>

      <div className={workStyle.info_cell}>
        <h3>Services</h3>
        <span className={workStyle.badge}><Link to="/services/website/">Création de site web</Link></span>
        <span className={workStyle.badge}><Link to="/services/referencement/">Référencement</Link></span>
        <span className={workStyle.badge}><Link to="/services/conseils/">Conseils</Link></span>
        
      </div>
      <div className={workStyle.info_cell}>
<div style={{marginBottom: "12px"}}>
        <h3>Secteur</h3>
          <p>Textile</p>
</div>
        <h3>Localisation</h3>
        <p>Mons (Be)</p>
        <p>Cagnes-Sur-Mer (Fr)</p>
        <p>Valréas (Fr)</p>
      
 
      </div>
      <div className={workStyle.info_cell}>
        <h3>Liens </h3>
        <p>
          <a
                      className={workStyle.external_link}

            href="http://www.idmagic.be/"
            target="_blank"
            rel="noopener noreferrer"
          >
            idmagic.be
          </a>
        </p>
        <p>
          <a
            className={workStyle.external_link}
            href="http://www.idmagicfrance.fr/"
            target="_blank"
            rel="noopener noreferrer"
          >
            idmagicfrance.fr
          </a>
        </p>
      </div>
    </div>

    <article className={pageStyle.article}>
      <h2>ID Magic</h2>
      <p>
        L’entreprise ID Magic est spécialisée dans l’impression et la broderie
        publicitaire sous tous types de textiles.
      </p> 
      <p>
        ID Magic a fait appel à MDS pour
        la création de son site internet dans le but d’élargir sa cible à
        l’échelle internationale et de se doter d’une vitrine de communication à
        l’image de son entreprise.
      </p>
     <p>
         Après avoir analysé les besoins d’ID Magic,
        nous avons conçu un site web mettant en lumière ses services et performant
        en matière de demandes de devis.
     </p>
      <div className={pageStyle.cta}>
      <Link to="/contact/">Contactez-nous pour en savoir plus</Link>
      </div>
    </article>
    <Footer />
  </div>
);
